<template>
  <v-container class="pa-0 mx-0">
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          v-blur
          class="msaBlue white--text"
          @click="resetSelection"
        >
          copy to
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Copy forms to </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <CustomizedAutoComplete
                :value="selectedCompanies"
                label="Select company(s)"
                hide-details
                :items="companies"
                multiple
                item-text="Name"
                item-value="ID"
                @change="selectedCompanies = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Forms</th>
                      <th class="text-center">Visible to company</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in selectedFormTemplates" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td class="text-center">
                        <v-btn
                          icon
                          :color="item.targetPublish ? 'msaBlue' : 'grey'"
                          @click="item.targetPublish = !item.targetPublish"
                        >
                          <v-icon v-if="item.targetPublish">mdi-eye</v-icon>
                          <v-icon v-else>mdi-eye-off</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="msaBlue" @click="close()"> cancel </v-btn>
          <v-btn class="msaBlue white--text" @click="confirm()"> copy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CustomizedAutoComplete from '@/components/CustomizedAutoComplete.vue';
export default {
  name: 'IACompanyFormTemplatesCopyToDialog',
  components: {
    CustomizedAutoComplete,
  },
  props: {
    initialFormTemplates: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dialog(val) {
      if (val && !this.companiesLoaded) {
        this.init();
      }
    },
  },
  data() {
    return {
      dialog: false,
      companies: [],
      companiesLoaded: false,
      selectedFormTemplates: [],
      selectedCompanies: [],
    };
  },
  methods: {
    init() {
      const params = {
        loaderText: 'Loading...',
      };
      const url = 'get-companies-for-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        this.companies = response.data.filter((c) => c['Status'] == 'Active');
        this.companiesLoaded = true;
      });
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      const formTemplates = this.selectedFormTemplates.map((f) => {
        return {
          id: f.id,
          isPublished: f.targetPublish,
        };
      });
      const params = {
        loaderText: 'Loading...',
        companyIds: this.selectedCompanies,
        formTemplates,
        snackText: 'Copy is done.',
      };
      const url = 'copy-form-templates?format=json';
      this.$axios.post(url, params).then((response) => {
        const failed = response.data;
        if (failed.length) {
          let message =
            '<div>There are some errors while copying the forms.</div><ul>';
          failed.forEach((msg) => {
            message += `<li>${msg}</li>`;
          });
          message += '</ul>';
          this.$root.showMessage(
            'Warning',
            message,
            () => null,
            () => null,
            'OK',
          );
        }

        this.close();
        this.$emit('confirm');
      });
    },
    resetSelection() {
      this.selectedFormTemplates = JSON.parse(
        JSON.stringify(this.initialFormTemplates),
      );
      this.selectedCompanies = [];
    },
  },
};
</script>
<style></style>
