<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="lightGrey">
            <v-row>
              <v-col class="shrink text-no-wrap">
                {{ pageTitle }}
              </v-col>
              <v-col v-if="selected.length > 0" class="shrink">
                <v-btn text color="msaBlue" @click="unselectAll()">
                  unselect all
                </v-btn>
              </v-col>
              <v-col v-if="selected.length > 0">
                <IACompanyFormTemplatesCopyToDialogVue
                  :initialFormTemplates="selected"
                  @confirm="unselectAll()"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-list>
              <v-list-group
                v-for="group in formTemplatesInGroups"
                :key="group.groupId"
                :value="true"
                no-action
                sub-group
              >
                <!-- Use to get rid of the default action arrow -->
                <template v-slot:prependIcon> <span></span> </template>
                <template v-slot:activator>
                  <v-list-item-content
                    :style="{ 'border-bottom': '1px solid' }"
                  >
                    <v-list-item-title class="font-weight-bold black--text">
                      <v-list-item-action>
                        <v-checkbox
                          hide-details
                          color="msaBlue"
                          @click.stop
                          class="pl-2"
                          :key="reloadGroupCheckbox"
                          :indeterminate="
                            group.selected.length > 0 &&
                            group.selected.length < group.formTemplates.length
                          "
                          :input-value="
                            group.selected.length == group.formTemplates.length
                          "
                          @change="toggleGroupSelectAll(group, $event)"
                        ></v-checkbox>
                      </v-list-item-action>
                      {{ group.groupName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="formTemplate in group.formTemplates"
                  :key="formTemplate.id"
                  class="ml-6"
                >
                  <v-list-item-content
                    :style="{ 'border-bottom': '1px solid' }"
                  >
                    <v-list-item-title class="pl-2">
                      <v-list-item-action>
                        <v-checkbox
                          v-model="group.selected"
                          hide-details
                          :value="formTemplate"
                          @change="reloadGroupCheckbox = !reloadGroupCheckbox"
                        ></v-checkbox>
                      </v-list-item-action>
                      {{ formTemplate.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IACompanyFormTemplatesCopyToDialogVue from '@/components/IACompanyFormTemplates/IACompanyFormTemplatesCopyToDialog.vue';
export default {
  name: 'IACompanyFormTemplatesPage',
  components: {
    IACompanyFormTemplatesCopyToDialogVue,
  },
  data() {
    return {
      formTemplatesInGroups: [],
      reloadGroupCheckbox: false,
    };
  },
  computed: {
    selected() {
      return this.formTemplatesInGroups
        .map((group) => JSON.parse(JSON.stringify(group.selected)))
        .flat();
    },
    pageTitle() {
      return this.selected.length > 0
        ? `${this.selected.length} SELECTED`
        : 'Form Templates';
    },
  },
  methods: {
    getFormTemplatesInGroups() {
      const params = {
        loaderText: 'loading...',
        companyId: this.$store.getters.selectedCompany.id,
      };
      const url = 'get-all-form-templates?format=json';
      this.$axios.post(url, params).then((response) => {
        this.formTemplatesInGroups = response.data.groups.filter(
          (g) => g.formTemplates.length > 0,
        );
        this.$store.commit('updateSelectedCompany', {
          id: response.data.company.id,
          name: response.data.company.name,
          status: response.data.company.status,
          companyGroupId: response.data.company.companyGroupId,
        });
      });
    },
    toggleGroupSelectAll(group, value) {
      if (value) {
        group.selected = JSON.parse(JSON.stringify(group.formTemplates));
      } else {
        group.selected = [];
      }
    },
    unselectAll() {
      this.formTemplatesInGroups.forEach((group) => {
        if (group.selected.length > 0) {
          group.selected = [];
        }
      });
    },
  },
  mounted() {
    this.getFormTemplatesInGroups();
  },
};
</script>

<style></style>
